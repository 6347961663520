import { render, staticRenderFns } from "./Parcel-Un-Inbound.vue?vue&type=template&id=65086ed5&scoped=true"
import script from "./Parcel-Un-Inbound.vue?vue&type=script&lang=js"
export * from "./Parcel-Un-Inbound.vue?vue&type=script&lang=js"
import style0 from "./Parcel-Un-Inbound.vue?vue&type=style&index=0&id=65086ed5&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65086ed5",
  null
  
)

export default component.exports
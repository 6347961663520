<template>
  <div class="container-Inbound">
    <div class="box-title" style="display:flex;justify-content: space-between">
      <h4>
        <i class="fal fa-scanner"></i>
        {{ $t('Sidebar.ຄັງພັດສະດຸສາຂາ') }} ({{ $t('pages.ຍິງເຄື່ອງເຂົ້າ') }})
        <span class="countTotal"></span>
        <vs-button
            :loading="isExporting"
            @click="exportShipmentOrdersToExcel"
            color="whatsapp" style="position: absolute;right: 0;top: 50px;">
          Export Excel
        </vs-button>
      </h4>
    </div>
    <div class="box" v-if="isRoleService === 'service'">
      <div class="columns">
        <div class="column is-12">
          <div class="control">
            <label class="label">{{ $t('ລາຍການOrderພັດສະດຸ.ເລືອກເບິ່ງຕາມສາຂາ') }}:</label>
            <div class="field">
              <div class="control">
                <model-list-select
                    :list="branches"
                    v-model="selectedBranch"
                    :placeholder="$t('ລາຍການOrderພັດສະດຸ.ເລືອກສາຂາ')"
                    option-text="name"
                    option-value="id"
                    selectedItem="name">
                </model-list-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-4">
          <div class="field">
            <div class="control">
              <label class="label">{{ $t("ລາຍການOrderພັດສະດຸ.ຄົ້ນຫາ") }}</label>
              <input
                  v-model="searchItem"
                  @keyup.enter="FilterData()"
                  class="input"
                  :placeholder="`${$t('ລາຍການOrderພັດສະດຸ.ຄົ້ນຫາ')}...`"
              >
            </div>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label class="label">{{ $t("ລາຍການOrderພັດສະດຸ.ຈາກວັນທີ") }}:</label>
            <date-picker
                v-model="start_date"
                type="date"
                :clearable="false"
                name="start_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-3">
          <div class="control">
            <label class="label">{{ $t("ລາຍການOrderພັດສະດຸ.ຫາວັນທີ") }}:</label>
            <date-picker
                v-model="end_date"
                type="date"
                :clearable="false"
                name="end_date"
            ></date-picker>
          </div>
        </div>
        <div class="column is-2">
          <div class="field">
            <div class="control">
              <vs-button
                  ref="button" flat
                  @click="FetchData"
                  class="form-btn-loading"
                  :loading="isLoading">
                {{ $t("ລາຍການOrderພັດສະດຸ.ຄົ້ນຫາ") }}
              </vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th></vs-th>
            <vs-th>{{ $t('ຂໍ້ມູນOrderDetail.ລຳດັບ') }}</vs-th>
            <vs-th>{{ $t('ຂໍ້ມູນOrderDetail.ເລກທີບິນ') }}</vs-th>
            <vs-th>{{ $t('ຂໍ້ມູນOrderDetail.ສາຂາຕົ້ນທາງ') }}</vs-th>
            <vs-th></vs-th>
            <vs-th>{{ $t('ຂໍ້ມູນOrderDetail.ສາຂາປາຍທາງ') }}</vs-th>
            <vs-th>{{ $t('ລາຍການOrderພັດສະດຸ.ລາຍລະອຽດບິນ') }}</vs-th>
            <vs-th>{{ $t('ຂໍ້ມູນOrderDetail.ຜູ້ຍິງພັດສະດຸ') }}</vs-th>
            <vs-th>{{ $t('ຂໍ້ມູນOrderDetail.ວັນເວລາຍິງ') }}</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr v-for="(item,index) in parcelInbound" :key="index">
            <vs-td style="text-align: right;">
              <div class="group-icon">
                <vs-button circle icon flat @click="openNewTab(item)">
                  <i class="fad fa-eye"></i>
                </vs-button>
              </div>
            </vs-td>
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td>{{ item.parcel_in_shipment.parcel_bill_number }}</vs-td>
            <vs-td>{{ item.shipment.start_branch.name }}</vs-td>
            <td style="vertical-align: middle;"><i style="color: red;font-size: 1.2rem"
                                                   class="fas fa-long-arrow-alt-right"></i></td>
            <vs-td>{{ item.shipment.end_branch.name }}</vs-td>
            <vs-td>{{ item.latest_tracking }}</vs-td>
            <vs-td>{{ item.created_by_user.name }}</vs-td>
            <vs-td>{{ FormatDateTime(item.created_at) }}</vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>

    </div>
  </div>
</template>

<script>
import Loading from "@views/Loading";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import pagination from "@/Plugins/Paginations/CusorPagination";
import * as helper from "@/Helpers/GetValue";
import {ModelListSelect} from 'vue-search-select';
import {FormatDate} from "@/Helpers/Format";

export default {
  name: "Parcel-Inbound",
  components: {
    DatePicker,
    pagination,
    Loading,
    ModelListSelect,
  },
  data() {
    return {
      branches: [],
      selectedBranch: null,
      start_date: moment().subtract(3, 'days').toDate(),
      end_date: new Date(),
      searchItem: "",
      parcelInbound: [],
      // Pagination
      page: 1,
      max: 10,
      offset: 10,
      count: 0,
      pagination: {},
      per_page: 10,
      limit_page: 10,
      isLoading: false,
      getBranchId: "",
      isRoleService: "",
      isExporting: false,
    }
  },
  watch: {
    'searchItem': function (value) {
      if (value == '') {
        this.FetchData();
      }
    },
    'start_date': function () {
      this.FetchData();
    },
    'end_date': function () {
      this.FetchData();
    },
    'selectedBranch': function (val) {
      this.getBranchId = val;
      this.FetchData();
    },
  },
  computed: {
    getUserBranchId() {
      return this.$store.getters.get_branch_id;
    }
  },
  methods: {
    FormatDateTime(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm')
      }
    },
    exportShipmentOrdersToExcel() {
      if (this.isExporting) {
        return;
      }
      this.isExporting = true;
      /// Preparing data
      const body = {
        shipment_pay_type: "",
        userId: null,
        start_branch: null,
        end_branch: null,
        sort_order: "desc",
        force_id: "true",
        request_inventory: "true",
        is_only_count: "false",
        branch_id: this.getBranchId ? this.getBranchId : (this.getUserBranchId || "all"),
        distribution_center_id: null,
        status: "inbound",
        query: this.searchItem,
        start_date: moment(this.start_date).format('YYYY-MM-DD 00:00:00.000'),
        end_date: moment(this.end_date).format('YYYY-MM-DD 23:59:59.000'),
        cursor: null,
        per_page: 12,
      };
      this.$http.post('v1/auth/branches/shipments/exports/orders/checking/shipment-holding', body,
          {responseType: 'blob'}).then((response) => {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        fileLink.setAttribute('download',
            `Exported - Shipments - ${this.$t(`pages.ຍິງເຄື່ອງເຂົ້າ`)} - Date at ${FormatDate(body.start_date)}.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.isExporting = false;
      }).catch(() => {
        this.isExporting = false;
      })
    },
    FetchListBranches() {
      this.$http.get('v1/listing/branches?type=&include=excluded').then((res) => {
        this.branches = res.data;
        this.branches.unshift({
          id: "all",
          name: this.$t("common.ທັງໝົດ"),
        });
      })
    },
    FilterData() {
      helper.GetOldValueOnInput(this);
    },
    countData() {
      return this.fetchItemsResponse({isCount: true}).then((res) => {
        if (!res.data.error) {
          this.$emit('dataCountUpdated', {
            'count': res.data.count,
            type: 'inbound_count'
          })
        }
      }).catch(() => {
      });
    },
    FetchData() {
      this.isLoading = true;
      this.fetchItemsResponse().then((res) => {
        if (!res.data.error) {
          setTimeout(() => {
            this.parcelInbound = res.data.data;
            this.pagination = res.data;
          }, 300);
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
      /// Counting data
      this.countData()
    },
    fetchItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('v1/auth/branches/shipments/orders/tracking/holding-shipments', {
        params: {
          shipment_pay_type: "",
          userId: null,
          start_branch: null,
          end_branch: null,
          sort_order: "desc",
          request_inventory: "true",
          force_id: true,
          is_only_count: options.isCount ?? false,
          branch_id: this.getBranchId ? this.getBranchId : (this.getUserBranchId || "all"),
          distribution_center_id: null,
          status: "inbound",
          query: this.searchItem,
          start_date: moment(this.start_date).format('YYYY-MM-DD 00:00:00.000'),
          end_date: moment(this.end_date).format('YYYY-MM-DD 23:59:59.000'),
          cursor: current_page ?? this.pagination.cursor,
          per_page: per_page ?? this.limit_page,
        }
      })
    },
    FormatDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm')
      }
    },
    openNewTab(item) {
      const Url = this.$router.resolve({
        name: 'branch.list.order.detail.inbound',
        params: {id: item.shipment_id}, query: {force: 'true'}
      });
      window.open(Url.href);
    },
  },
  created() {
    this.FetchListBranches();
    this.selectedBranch = this.getUserBranchId;
    this.isRoleService = this.$store.getters.checkIsServiceRole;
  }
}
</script>

<style scoped>

</style>

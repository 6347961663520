<template>
  <div class="container">
    <div class="tabs is-centered" style="background-color: #FFFFFF;">
      <ul style="margin: 0">
        <li :class="{'is-active': activeIndex === index}"
            v-for="(tab,index) in tabsData" :key="index" @click="changeTabs(index)">
          <a>
            <span class="label-input">{{ $t(`pages.${tab.name}`) }}</span>
            <div class="badge-color">
              ({{ tab.count }})
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <div class="tab-item">
        <keep-alive>
          <component :is="selectedTab.component"
                     :ref="selectedTab.ref"
                     @dataCountUpdated="updateDataCount"
                     :checkUser="checkUser"/>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import ParcelInbound from "@views/SuperAdmin/Parcel/ParcelBranch/TabStatus/Parcel-Inbound";
import ParcelInboundWaiting from "@views/SuperAdmin/Parcel/ParcelBranch/TabStatus/Parcel-Un-Inbound";
import ParcelOutBound from "@views/SuperAdmin/Parcel/ParcelBranch/TabStatus/ParcelOutBound";
import ParcelOutBoundWaiting from "@views/SuperAdmin/Parcel/ParcelBranch/TabStatus/Parcel-UnOutBound";

import axios from "axios";
import moment from "moment/moment";

export default {
  name: "Index",
  computed: {
    selectedTab() {
      return this.tabsData[this.activeIndex]
    },
  },
  data() {
    return {
      activeIndex: 0,
      tabsData: [
        {
          name: "ເຄື່ອງຄ້າງສາງ",
          component: ParcelOutBoundWaiting,
          count: 0,
          ref: 'unOutbound'
        },
        {
          name: "ຍິງເຄື່ອງອອກ",
          component: ParcelOutBound,
          count: 0,
          ref: 'outbound'
        },
        {
          name: "ຍິງເຄື່ອງເຂົ້າ",
          component: ParcelInbound,
          count: 0,
          ref: 'inbound'
        },
        {
          name: "ລໍຖ້າຍິງເຂົ້າ",
          component: ParcelInboundWaiting,
          count: 0,
          ref: 'unInbound'
        }
      ],
      checkUser: {
        branch: {}
      },
    }
  },
  methods: {
    changeTabs(index) {
      this.activeIndex = index;
    },
    updateDataCount(dataCount) {
      this.tabsData[this.activeIndex].count = dataCount.count || 0;
    },
    fetchCount(status, days, month = 0) {
      const isMonth = month > 0;
      return this.$http.get('v1/auth/branches/shipments/orders/tracking/holding-shipments', {
        params: {
          shipment_pay_type: "",
          userId: null,
          start_branch: null,
          end_branch: null,
          sort_order: "desc",
          force_id: true,
          is_only_count: true,
          request_inventory: "true",
          branch_id: this.$store.getters.get_branch_id ? this.$store.getters.get_branch_id : 1,
          distribution_center_id: null,
          status,
          query: '',
          start_date: moment().subtract(isMonth ? month : days, isMonth ? "months" : "days").format('YYYY-MM-DD 00:00:00.000'),
          end_date: moment(this.end_date).format('YYYY-MM-DD 23:59:59.000'),
          cursor: null,
          per_page: 10,
        }
      })
    },
    fetchTabsCount() {
      axios.all([
        this.fetchCount("outbound", 3),
        this.fetchCount("inbound", 3),
        this.fetchCount("un-inbound", 3),
      ]).then((responses) => {
        responses.forEach((response, key) => {
          this.tabsData[key + 1].count = response.data.count
        })
      }).catch((errors) => {
        console.log("fetchTabsCount::errors=>", errors)
      })
    }
  },
  watch: {
    checkUser: function (val) {
      if (val && val.branch) {
        this.$store.commit("set_BranchId", val.branch == null ? 1 : val.branch.id)
      }
    }
  },
  created() {
    this.$http.get(`list-my-profile`).then((res) => {
      this.checkUser = res.data;
      if (this.checkUser && this.checkUser.branch) {
        this.$store.commit("set_BranchId", this.checkUser.branch.id)
      } else {
        this.$store.commit("set_BranchId", 1)
      }
      const unOutboundRef = this.$refs.unOutbound;
      if(unOutboundRef) {
        unOutboundRef.firstInit()
      }
      this.fetchTabsCount();
    }).catch(() => {
    })
    if (this.$can('service')) {
      this.$store.commit("set_service_role", "service")
    }
  }
}
</script>

<style scoped lang="scss">
.badge-color {
  font-weight: 900;
  margin-left: 12px;
  color: red;
}
</style>
